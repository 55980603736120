import {
    IonContent,
    IonHeader,
    IonPage,
    IonToolbar,  IonButton, IonImg, IonBackButton, IonButtons
} from '@ionic/react';
import React from 'react';
import {RouteComponentProps} from "react-router";

const Choose: React.FC<RouteComponentProps> = (props) => {
  return (
    <IonPage>
      <IonHeader>
        <IonToolbar color="danger">
            <IonButtons slot="start">
                <IonBackButton defaultHref="/home"  />
              </IonButtons>
              <IonImg style={styles.imgStyle} src="https://southernmedpeds.com/app/assets/img/new_logo.png"/>
        </IonToolbar>
      </IonHeader>
      <IonContent color="danger" className="ion-padding">
        <IonButton size="large" color="light" expand="block" onClick={() => props.history.push('/schedule/1')}>Batesburg</IonButton><br />
          <IonButton size="large" color="light" expand="block" onClick={() => props.history.push('/schedule/2')}>Fort Mill</IonButton><br />
          <IonButton size="large" color="light" expand="block" onClick={() => props.history.push('/schedule/7')}>Garners Ferry</IonButton><br />
          <IonButton size="large" color="light" expand="block" onClick={() => props.history.push('/schedule/3')}>Hillcrest</IonButton><br />
          <IonButton size="large" color="light" expand="block" onClick={() => props.history.push('/schedule/4')}>Lake Murray</IonButton><br />
          <IonButton size="large" color="light" expand="block" onClick={() => props.history.push('/schedule/5')}>North East</IonButton><br />
          <IonButton size="large" color="light" expand="block" onClick={() => props.history.push('/schedule/6')}>South Lake</IonButton><br />
      </IonContent>
    </IonPage>
  );
};

const styles = {
    imgStyle : {
        flex: 1,
        alignSelf: 'stretch',
        marginLeft: 5,
        marginRight: 5,
        height:70,
        marginTop:10,
        marginBottom: 10,
        justifyContent: 'center',
    }
}

export default Choose;
