import {
    IonContent,
    IonHeader,
    IonPage,
    IonToolbar, IonImg,  IonBackButton, IonButtons
} from '@ionic/react';
import React from 'react';
import {RouteComponentProps} from "react-router";

interface PracticePageProps extends RouteComponentProps<{
  id: string;
}> {}

const Shot: React.FC<PracticePageProps> = ({match}) => {

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar color="danger">
            <IonButtons slot="start">
                <IonBackButton defaultHref="/home"  />
              </IonButtons>
              <IonImg style={styles.imgStyle} src="https://southernmedpeds.com/app/assets/img/new_logo.png"/>
        </IonToolbar>
      </IonHeader>
        <IonContent>
            <iframe src="https://southernmedpeds.com/app/shots?os=web" width="100%" height="100%" title="secure online form"></iframe>
        </IonContent>

    </IonPage>
  );
};

const styles = {
    imgStyle : {
        flex: 1,
        alignSelf: 'stretch',
        marginLeft: 5,
        marginRight: 5,
        height:70,
        marginTop:10,
        marginBottom: 10,
        justifyContent: 'center',
    }
}

export default Shot;
