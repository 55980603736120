import {
    IonContent,
    IonHeader,
    IonPage,
    IonToolbar, IonButton, IonImg, IonModal, IonGrid, IonRow, IonCol, IonCard, IonCardContent
} from '@ionic/react';
import React, {useState} from 'react';
import {RouteComponentProps} from "react-router";


const Home: React.FC<RouteComponentProps> = (props) => {
    const [showModal, setShowModal] = useState(false);
    const [showCust, setShowCust] = useState(false);
  return (
    <IonPage>
      <IonHeader>
        <IonToolbar color="danger">

              <IonImg style={styles.imgStyle} src="https://southernmedpeds.com/app/assets/img/new_logo.png"/>
        </IonToolbar>
      </IonHeader>
      <IonContent color="danger" className="ion-padding">

        <IonButton size="large" color="light" expand="block" onClick={() => {
            setShowModal(true);
        }}>Schedule an Appointment</IonButton><br />
          <IonButton size="large" color="light" onClick={() => {window.open('http://southernmedpeds.com/current-patients/pay-my-bill#pay', '_system', 'location=yes'); return false;}} expand="block">Pay My Bill</IonButton><br />
          <IonButton size="large" color="light" onClick={() => {
                      setShowCust(true);
                  }} expand="block">Become a Patient</IonButton><br />
          <IonButton size="large" color="light" onClick={() => {window.open('https://practisforms.com/southernmedllc/forms/shot-record-request', '_system', 'location=yes'); return false;}}expand="block">Request a Shot Record</IonButton><br />
          <IonButton size="large" color="light" onClick={() => {window.open('http://southernmedpeds.com/', '_system', 'location=yes'); return false;}}  expand="block">About Us</IonButton><br />
          <IonButton size="large" color="light" onClick={() => {window.open('tel:8035205800', '_system', 'location=yes'); return false;}}  expand="block">Call Us</IonButton><br />
          <IonModal isOpen={showModal}>
                  <IonCard>
                      <IonCardContent>
                            <h1>WE ARE UNABLE TO ACCOMMODATE WELL CHECKS OR MEDICATION CHECKS WITH OUR ONLINE SCHEDULING AT THIS TIME.</h1><br />

                            <h1>For well checks, sports physicals, and medication follow-up appointments, please call our office.</h1><br />

                            <h1>To book an online appointment, you must be an ESTABLISHED patient (seen in our office at least once).</h1><br />
                      </IonCardContent>
                  </IonCard>
                              <IonGrid>
                                  <IonRow>
                                      <IonCol>
                                          <IonButton onClick={() => {
                                                            setShowModal(false)
                                                            props.history.push('/choose')}}>Continue</IonButton>
                                      </IonCol>
                                      <IonCol>
                                          <IonButton onClick={() => setShowModal(false)}>Cancel</IonButton>
                                      </IonCol>
                                  </IonRow>
                              </IonGrid>



            </IonModal>

              <IonModal isOpen={showCust}>
                    <IonCard>
                        <IonCardContent>
                            <h1>Congratulations !  You’ve completed the first step for your child to become our patient!</h1><br />

                            <h1>The next step is to complete a medical records release so that we can request your child’s records from their previous doctor.  One of our team members will call you after we receive the records to let you know that your child is set up in our system and able to schedule appointments.</h1><br />

                            <h1>We look forward to meeting you soon!</h1><br />
                        </IonCardContent>
                    </IonCard>
                                <IonGrid>
                                    <IonRow>
                                        <IonCol>
                                            <IonButton onClick={() => {
                                                              setShowCust(false)
                                                            window.open('https://practisforms.com/southernmedllc/forms/medical-records-release-form', '_system', 'location=yes')}}>Continue</IonButton>
                                        </IonCol>
                                        <IonCol>
                                            <IonButton onClick={() => setShowCust(false)}>Cancel</IonButton>
                                        </IonCol>
                                    </IonRow>
                                </IonGrid>



              </IonModal>
      </IonContent>
    </IonPage>
  );
};

const styles = {
    imgStyle : {
        flex: 1,
        alignSelf: 'stretch',
        marginLeft: 5,
        marginRight: 5,
        height:70,
        marginTop:10,
        marginBottom: 10,
        justifyContent: 'center',
    },
    modalStyle : {
        paddingLeft: 10,
        paddingRight: 10
    }
}

export default Home;
