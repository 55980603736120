import React, { Component } from 'react';
import {
    IonContent
} from '@ionic/react';

class LoadSchedule extends Component<{practice: string}> {

    renderSchedule = () => {
        switch (this.props.practice) {
            case '1':
                return <iframe title="Batesburg" src="https://next.lumahealth.io/scheduler/5c75937e2140f000eb7d77c7?facility=5c824cd030dd1aaa1178dfff" width="100%" height="100%" frameBorder="0" />
            case '2':
                return <iframe title="Fort Mill" src="https://next.lumahealth.io/scheduler/5c75937e2140f000eb7d77c7?facility=5c824cd030dd1aaa1178e003" width="100%" height="100%" frameBorder="0" />
            case '3':
                return <iframe title="Hillcrest" src="https://next.lumahealth.io/scheduler/5c75937e2140f000eb7d77c7?facility=5c824cd030dd1aaa1178e005" width="100%" height="100%" frameBorder="0" />
            case '4':
                return <iframe title="Lake Murray" src="https://next.lumahealth.io/scheduler/5c75937e2140f000eb7d77c7?facility=5c824cd030dd1aaa1178e007" width="100%" height="100%" frameBorder="0" />
            case '5':
                return <iframe title="North East" src="https://next.lumahealth.io/scheduler/5c75937e2140f000eb7d77c7?facility=5c824cd030dd1aaa1178e009" width="100%" height="100%" frameBorder="0" />
            case '6':
                return <iframe title="South Lake" src="https://next.lumahealth.io/scheduler/5c75937e2140f000eb7d77c7?facility=5c824cd130dd1aaa1178e00b" width="100%" height="100%" frameBorder="0" />
            case '7':
                return <iframe title="Garners Ferry" src="https://next.lumahealth.io/scheduler/5c75937e2140f000eb7d77c7?appointmentType=5c824cec30dd1aaa1178e0a8%2Cnull&facility=5dc53efe43ecb67adfe95b3a" width="100%" height="100%" frameBorder="0" />
        }
    }

  render() {
        return  (
            <IonContent>
                {this.renderSchedule()}
            </IonContent>
        )
  }
}

export default LoadSchedule;