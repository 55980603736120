import {
    IonHeader,
    IonPage,
    IonToolbar, IonImg, IonBackButton, IonButtons
} from '@ionic/react';
import React from 'react';
import {RouteComponentProps} from "react-router";
import LoadSchedule from './LoadSchedule';

interface PracticePageProps extends RouteComponentProps<{
  id: string;
}> {}

const Schedule: React.FC<PracticePageProps> = ({match}) => {

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar color="danger">
            <IonButtons slot="start">
                <IonBackButton defaultHref="/choose"  />
              </IonButtons>
              <IonImg style={styles.imgStyle} src="https://southernmedpeds.com/app/assets/img/new_logo.png"/>
        </IonToolbar>
      </IonHeader>

          <LoadSchedule practice={match.params.id} />
    </IonPage>
  );
};

const styles = {
    imgStyle : {
        flex: 1,
        alignSelf: 'stretch',
        marginLeft: 5,
        marginRight: 5,
        height:70,
        marginTop:10,
        marginBottom: 10,
        justifyContent: 'center',
    }
}

export default Schedule;
